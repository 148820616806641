import { Contact } from "../components/Contact";
import underConstructionImage from "../assets/under-construction.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export const ManufacturingFacilityPage = () => {
  return (
    <div>
      <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full py-14 md:py-24 space-y-12 px-5 md:px-0">
        <div className="flex flex-col items-center gap-4">
          <div className="text-5xl font-bold">Manufacturing Facility</div>
        </div>

        <div className="max-w-7xl w-full mx-auto grid grid-cols-12 gap-10">
          <div className="col-span-7">
            <Carousel
              autoPlay
              dynamicHeight
              infiniteLoop
              showIndicators={false}
            >
              {[
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19,
                20, 21, 22, 24, 25, 26, 27, 28,
              ].map((index) => (
                <div>
                  <img src={`/factory/${index}.jpg`} />
                </div>
              ))}
            </Carousel>
          </div>

          <div className="col-span-5 flex flex-col gap-3 text-lg">
            <div>
              At Weco Polymer Fusion, our state-of-the-art manufacturing
              facility stands as a testament to our commitment to quality and
              innovation. Equipped with the latest technology and operated by a
              skilled team of professionals, our facility is designed to handle
              every aspect of production with precision and efficiency. From the
              initial design phase through to the final inspection, we ensure
              that each product meets our rigorous standards and exceeds
              customer expectations.
            </div>

            <div>
              Our facility not only emphasizes cutting-edge equipment but also
              fosters an environment of continuous improvement and
              sustainability. We implement eco-friendly practices to minimize
              our environmental footprint while maximizing operational
              excellence. This dedication to both technology and responsibility
              underpins our promise to deliver superior products that drive
              success for our clients.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { Contact } from "../components/Contact";
import { Button } from "../components/Common/Button";
import { useNavigate } from "react-router-dom";

export const AboutPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full py-14 md:py-24 space-y-12 px-5 md:px-0 !max-w-6xl">
        <div className="flex flex-col items-center gap-4">
          <div className="text-5xl font-bold">About Weco Polymer Fusion</div>
          <div className="text-gray-500 text-center">
            Priding ourselves on delivering on customer satisfaction before
            anything else.
          </div>
        </div>

        <div className="grid grid-cols-12 gap-10">
          <div className="col-span-12 space-y-12 w-full">
            <div className="text-center">
              Welcome to Weco Polymer Fusion, your premier destination for
              high-quality polymer welding equipment. At Weco, we are dedicated
              to revolutionizing the polymer welding industry by providing
              innovative solutions and exceptional customer service.
            </div>

            <div className="grid grid-cols-12 items-center gap-10">
              <div className="col-span-4">
                <img src="/mission.png" />
              </div>

              <div className="space-y-8 col-span-7">
                <div className="font-bold text-2xl">Our Mission</div>
                <div>
                  At Weco Polymer Fusion, our mission is to empower
                  professionals in the polymer welding industry with the tools
                  they need to achieve unparalleled precision and efficiency in
                  their work. We strive to push the boundaries of technology and
                  engineering to deliver cutting-edge equipment that meets the
                  evolving needs of our customers.
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 items-center gap-10">
              <div className="space-y-5 col-span-8">
                <div className="font-bold text-2xl">
                  Expertise and Innovation
                </div>
                <div>
                  With years of experience and expertise in polymer welding, our
                  team at Weco is committed to staying at the forefront of
                  technological advancements. We continuously research, develop,
                  and refine our products to ensure they meet the highest
                  standards of quality and performance. Our dedication to
                  innovation drives us to create solutions that streamline
                  processes, enhance productivity, and deliver superior results.
                </div>
              </div>

              <div className="col-span-4">
                <img src="https://www.pngall.com/wp-content/uploads/2016/07/Innovation-Free-Download-PNG.png" />
              </div>
            </div>

            <div className="grid grid-cols-12 items-center gap-10">
              <div className="col-span-4">
                <img
                  className="h-60 w-auto mx-auto"
                  src="https://integratedelectronics.co.in/wp-content/uploads/2018/07/Quality-Assurance-PNG-Image-Background.png "
                />
              </div>

              <div className="space-y-8 col-span-7">
                <div className="font-bold text-2xl">Quality Assurance</div>
                <div>
                  At Weco Polymer Fusion, quality is our top priority. We adhere
                  to rigorous quality control standards throughout the
                  manufacturing process to guarantee the reliability,
                  durability, and safety of our equipment. Each product is
                  meticulously inspected and tested to ensure it meets our
                  stringent quality criteria before it reaches our customers.
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 items-center gap-10">
              <div className="space-y-5 col-span-8">
                <div className="font-bold text-2xl">
                  Exceptional Customer Service
                </div>
                <div>
                  At Weco, we believe that building strong relationships with
                  our customers is essential to our success. That's why we are
                  committed to providing exceptional customer service every step
                  of the way. Our knowledgeable team is here to offer guidance,
                  technical support, and assistance to ensure you get the most
                  out of your Weco equipment. We value your feedback and are
                  continuously striving to exceed your expectations.
                </div>
              </div>
              <div className="col-span-4">
                <img src="https://png.pngtree.com/png-clipart/20231015/original/pngtree-3d-customer-service-operator-png-illustration-png-image_13302774.png" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-quote-section">
        <div className="bg-background/90 h-full w-full">
          <div className="mx-auto lg:container lg:px-16 xl:px-20 h-full w-full py-40 text-center capitalize">
            <div className="text-primary font-bold tracking-widest uppercase mb-5">
              Get a Quote
            </div>

            <div className="text-white font-bold text-5xl">
              Are you prepared to{" "}
              <span className="text-primary">get your project started</span>?
            </div>
            <div className="text-white font-bold text-5xl mb-10">
              Get a quote right now!
            </div>

            <Button
              className="!font-semibold"
              buttonStyle="primary"
              label="Get Started"
              size="large"
              rightIcon
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                    clipRule="evenodd"
                  />
                </svg>
              }
              onClick={() => navigate("/contact")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

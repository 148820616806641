import { Button } from "./Common/Button";
import { NavItem } from "./NavItem";
import { useNavigate } from "react-router-dom";
import { Logo } from "./Common/Logo";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

export const Navigation = () => {
  const NAV_ITEMS = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "About",
      href: "/about",
    },
    {
      label: "Products",
      href: "/products",
    },
    {
      label: "Manufacturing Facility",
      href: "/manufacturing-facility",
    },
    {
      label: "Contact",
      href: "/contact",
    },
  ];

  const navigate = useNavigate();

  return (
    <div
      className="sticky top-0 z-40 transform"
      style={{ transform: "translate3d(0, 0, 999px)" }}
    >
      <div className="absolute inset-0 h-full w-full bg-background border-b-8 border-accent"></div>
      <nav className="relative z-40">
        <div className="relative flex justify-between h-20 mx-auto lg:container lg:px-16 xl:px-20">
          <div className="flex items-center px-6 lg:px-0 flex-1 sm:items-stretch justify-between">
            <div className="flex items-center justify-between md:justify-normal flex-1">
              <div className="flex items-center flex-shrink-0">
                <a
                  className="flex items-center gap-1.5 w-auto h-6 focus-visible:outline-none font-bold text-xl"
                  onClick={() => navigate("/")}
                >
                  <Logo className="h-8 md:h-14 w-auto" />
                </a>
              </div>
              <nav className="relative z-10 flex-1 items-center justify-end hidden pl-8 sm:space-x-8 lg:flex h-16">
                <div>
                  <ul className="group flex flex-1 list-none items-center justify-center space-x-5">
                    {NAV_ITEMS.map((navItem) => (
                      <NavItem
                        key={navItem.href}
                        label={navItem.label}
                        href={navItem.href}
                      />
                    ))}
                  </ul>
                </div>
                <Button
                  className="!font-semibold"
                  buttonStyle="primary"
                  label="Call Us Today"
                  size="large"
                  rightIcon
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                  onClick={() => navigate("/contact")}
                />
              </nav>

              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="md:!hidden">
                    <Button
                      buttonStyle="primary"
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-7 h-7"
                        >
                          <path
                            fillRule="evenodd"
                            d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      }
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-[89vw] origin-top-right rounded-md bg-background shadow-lg ring-1 ring-primary focus:outline-none">
                    <div className="px-1 py-1">
                      {NAV_ITEMS.map((navItem) => (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                active
                                  ? "text-primary"
                                  : "text-white font-semibold"
                              } group flex w-full items-center rounded-md px-3 py-4`}
                              onClick={() => navigate(navItem.href)}
                            >
                              {navItem.label}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

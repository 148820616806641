export const InputField = ({
  label,
  name,
  type,
  value,
  rows,
  onChange,
  placeholder,
  error,
  className,
  inputClassName,
  icon,
  onBlur,
  required,
  autoFocus = false,
  endComponent,
  hint,
  min,
  max,
  disabled = false,
  multiple = false,
  inputRef,
  iconClassName,
}) => {
  const textarea = type === "textarea";
  const resolvedInputClassName = [
    "w-full",
    "transition ease-in-out",
    "bg-gray-100 border border-gray-200 focus:border-gray-300 hover:border-gray-300 text-background placeholder:text-gray-400",
    "outline-none rounded-md",
    textarea ? "h-auto" : "h-14",
    error ? "border border-red-600" : "",
    icon ? "pl-8 pr-4" : "px-5",
    textarea ? "py-3" : "",
    inputClassName,
  ].join(" ");

  let input;
  if (textarea) {
    input = (
      <textarea
        id={name}
        name={name}
        value={value || ""}
        rows={rows}
        onChange={onChange}
        placeholder={placeholder}
        className={resolvedInputClassName}
        onBlur={onBlur}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    );
  } else {
    input = (
      <input
        ref={inputRef}
        id={name}
        type={type}
        name={name}
        value={value || ""}
        onChange={onChange}
        placeholder={placeholder}
        className={resolvedInputClassName}
        onBlur={onBlur}
        autoComplete="off"
        autoFocus={autoFocus}
        min={min}
        max={max}
        disabled={disabled}
        multiple={multiple}
      />
    );
  }

  return (
    <div className={`${className} grid gap-1 w-full`}>
      {label && (
        <label
          className="text-background font-medium flex items-center gap-1.5"
          htmlFor={name}
        >
          {label}
          {required && <div className="text-red-600">*</div>}
        </label>
      )}
      <div className="relative w-full">
        {input}
        {icon && icon}
        {endComponent && (
          <div className="absolute top-0 right-0 flex items-center h-full pr-3">
            {endComponent}
          </div>
        )}
      </div>
      {error && <p className="text-red-600 text-xs">{error}</p>}
      {hint && (
        <p className="text-scale-900 dark:text-brand-text-alt text-xs px-1">
          {hint}
        </p>
      )}
    </div>
  );
};

import { useNavigate } from "react-router-dom";

export const FooterLink = ({ text, local = false, href }) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex items-center gap-1.5 group cursor-pointer"
      onClick={() => {
        if (local) {
          navigate(href);
        } else {
          window.open(href, "_blank");
        }
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-5 h-5 text-primary group-hover:translate-x-0.5 transition ease-in-out"
      >
        <path
          fillRule="evenodd"
          d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
          clipRule="evenodd"
        />
      </svg>

      <div className="text-white font-medium">{text}</div>
    </div>
  );
};
